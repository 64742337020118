import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-npsdashboard-resume',
  templateUrl: './npsdashboard-resume.component.html',
  styleUrls: ['./npsdashboard-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NpsdashboardResumeComponent implements OnInit {
  public _npsStats: any;
  public _graphStats: any;
  public _graphStatsOptions: any;

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this._graphStatsOptions = {
      chart: {
        type: 'pieChart',
        height: 350,
        margin: { top: 20, right: 20, bottom: 50, left: 55 },
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        showValues: true,
        duration: 500
      }
    };

    this.loadNPSStats();
  }

  loadNPSStats() {
    this.adminApi.getDashboardNPSResume().then((resume: any) => {
      this._npsStats = resume;
      this._graphStats = [
        { label: 'Emails enviados', value: this._npsStats.emailSended },
        { label: 'Finalizadas', value: this._npsStats.finished },
        { label: 'Emails pendientes', value: this._npsStats.pendingEmailSend }
      ];
    });
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-alert-page',
  templateUrl: './alert-page.component.html',
  styleUrls: ['./alert-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertPageComponent implements OnInit {
  public _alertModules: any;

  constructor(
    private adminApi: AdminApiService
  ) { }

  ngOnInit() {
    this.adminApi.getAlertsModules()
      .then((modules) => {
        this._alertModules = modules;
      });
  }

}

import { environment } from "./../environments/environment";
import { User } from "./bqdata-model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

@Injectable()
export class UserApiService {
  constructor(private _http: HttpClient) {}

  profile() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/users/me")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  generateRandomPassword() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/users/me/generate-password", {})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  updatePassword(oPassword: string, nPassword: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/users/me/change-password", {
          oPassword: oPassword,
          nPassword: nPassword
        })
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  updateProfile(userData: User) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/users/me/update-profile", userData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
}

import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mail-template-list',
  templateUrl: './mail-template-list.component.html',
  styleUrls: ['./mail-template-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MailTemplateListComponent implements OnInit {
  public _emailTemplates: Object;

  @Output() onEditTemplate = new EventEmitter<string>();

  constructor( private adminApi: AdminApiService ) { }

  ngOnInit() {
      this.loadTemplates();
  }

  editTemplate( _tplId: string ) {
    this.onEditTemplate.emit(_tplId);
  }

  loadTemplates() {
    this.adminApi.getEmailTemplates()
      .then((templates) => {
        this._emailTemplates = templates;
      })
      .catch((error) => {
        console.dir(error);
      });
  }
}

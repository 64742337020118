import { AdminApiService } from './../admin-api.service';
import { StoreApiService } from './../store-api.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-admin-device-edit',
  templateUrl: './admin-device-edit.component.html',
  styleUrls: ['./admin-device-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminDeviceEditComponent implements OnInit, OnChanges {
  public storeList: any;
  public currentDevice: any;
  private _deviceId: string;

  @Output() onEditEnded = new EventEmitter<boolean>();

  @Input()
  set deviceId(deviceId: string) {
    this._deviceId = deviceId;
  }
  get deviceId(): string {
    return this._deviceId;
  }

  constructor(
    private storeApi: StoreApiService,
    private adminApi: AdminApiService
  ) {}

  ngOnInit() {
    this.currentDevice = {
      code: '',
      name: '',
      UUID: '',
      store: null
    };
    this.storeList = [];

    this.storeApi
      .getStoreList()
      .then((res: any) => {
        this.storeList = res.items;
      })
      .catch(err => {
        console.dir(err);
      });
  }

  goBack() {
    this.onEditEnded.emit(false);
  }

  saveDevice() {
    this.adminApi
      .updateDevice(this._deviceId, this.currentDevice)
      .then(res => {
        this.onEditEnded.emit(true);
      })
      .catch(err => {});
  }

  ngOnChanges() {
    this.adminApi.deviceDetail(this._deviceId).then(device => {
      this.currentDevice = device;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { User } from './../bqdata-model';
import { AdminApiService } from './../admin-api.service';
import { BqAuthService } from './../bq-auth.service';
import { ExcelService } from './../excel.service';

@Component({
  selector: 'app-activity-users',
  templateUrl: './activity-users.component.html',
  styleUrls: ['./activity-users.component.scss']
})
export class ActivityUsersComponent implements OnInit {
  list = [];
  constructor(
    private adminApi: AdminApiService,
    private bqAuth: BqAuthService,
    private excelService:ExcelService
  ) { }

  ngOnInit() {
  }
  verInactivos(){
    console.log('ver inactivos');
    this._getInac();
  }
  _getInac(){
    this.adminApi
    .inactiveUsers()
    .then((res: any) => {
      console.log(res);
      this.list = res.items;
      // this._userList = res.items;
      // this._totalUsers = res.total;
      // this._totalPages = Math.round(res.total / this._itemsByPage) + 1;
      // this._updateList = false;
    })
    .catch(err => {});
  }
  exportAsXLSX():void {
    if (this.list.length == 0){
      console.log("nohay datos")
    }else{
      console.log('exportar');
      let aux = [];
      for(let item of this.list){
        aux.push(
          {
            nombre: item.name,
            apellido: item.surname,
            tienda: item.mainStore.name ,
            ultimaConexion: item.token_expire
          }
        )
      }
        console.log('asd');
        let getRouteName = Date.now().toString()+'Usuarios Inactivo '+Math.random();
        this.excelService.exportAsExcelFile(aux, getRouteName);
     
    }
  }
  verActividad(){
    console.log('ver Act');
    this._getAct();
  }
  verActividadAdmin(){
    console.log('ver Act ADM');
    this._getActAd();

  }
  _getAct(){
    let list = [];
    this.adminApi
    .activityUsers()
    .then((res: any) => {
      console.log(res);
      for(let activity of res.items){
        console.log(activity);  
        let tienda;
        if(activity.user.mainStore != null){
          tienda = activity.user.mainStore.name;
        }else{
          tienda = "No Asignada"
        }
        list.push({
          Nombre : activity.user.name,
          Apellido : activity.user.surname,
          Tienda : tienda,
          Actividad: 'Inicio de Sesión',
          fecha: activity.created.date
        });
      }
      let getRouteName = Date.now().toString()+' Actividad de usuarios '+Math.random();
      this.excelService.exportAsExcelFile(list, getRouteName);
      // this.list = res.items;
      // this._userList = res.items;
      // this._totalUsers = res.total;
      // this._totalPages = Math.round(res.total / this._itemsByPage) + 1;
      // this._updateList = false;
    })
    .catch(err => {});
  }
  _getActAd(){
    let list = [];
    this.adminApi
    .activityUsersAdmin()
    .then((res: any) => {
      console.log(res);
      for(let activity of res.items){
        console.log(activity);  
        let tienda;
        if(activity.user.mainStore != null){
          tienda 
        }else{
          tienda = "No Asignada"
        }
        list.push({
          Nombre : activity.user.name,
          Apellido : activity.user.surname,
          Tienda : tienda,
          Actividad: 'Inicio de Sesión',
          fecha: activity.created.date
        });
      }
      let getRouteName = Date.now().toString()+' Actividad de usuarios '+Math.random();
      this.excelService.exportAsExcelFile(list, getRouteName);
      // this.list = res.items;
      // this._userList = res.items;
      // this._totalUsers = res.total;
      // this._totalPages = Math.round(res.total / this._itemsByPage) + 1;
      // this._updateList = false;
    })
    .catch(err => {});
  }
}

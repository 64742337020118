import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AdminApiService } from './../admin-api.service';

@Component({
  selector: 'app-export-page',
  templateUrl: './export-page.component.html',
  styleUrls: ['./export-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportPageComponent implements OnInit {
  public _exportNPS: boolean;
  public _exportHP: boolean;

  public _queryHP: any;

  public _errorDate: boolean;
  public _canGenerate: boolean;
  public _generating: boolean;
  public _hpExtractLastMonth: boolean;
  public _hpExtractLastYear: boolean;

  public _npsFiles: any;
  public _storeList: any;

  public _fileToExtract: string;

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef,
  ) {
    this._exportHP = false;
    this._exportNPS = false;

    this._queryHP = {
      start: null,
      end: null,
      stores: '',
    };

    this._errorDate = false;
    this._canGenerate = false;
    this._generating = false;
    this._fileToExtract = '';
  }

  ngOnInit() {
    this.checkDates();
    this.loadData();
  }

  loadData() {
    this.adminApi.listNPSFiles().then(npsFiles => {
      this._npsFiles = npsFiles;
    });

    this.adminApi.listStoresSelect().then(stores => {
      this._storeList = stores;

      this._exportHP = true;
    });
  }

  showExportHP() {
    this._exportHP = true;
    this.checkDates();
  }

  showExportNPS() {
    // this._exportNPS = true;
  }

  generate() {
    this._generating = true;

    if (this._exportNPS && this._fileToExtract === '') {
      this.toastr.error('Debe seleccionar un fichero para extraer.');
      this._generating = false;
      return;
    }

    if (this._exportHP) {
      let mStart = moment(
        this._queryHP.start.year +
          '-' +
          this._queryHP.start.month +
          '-' +
          this._queryHP.start.day,
        'YYYY-M-DD',
      ).unix();
      let mEnd = moment(
        this._queryHP.end.year +
          '-' +
          this._queryHP.end.month +
          '-' +
          this._queryHP.end.day,
        'YYYY-M-DD',
      ).unix();
      this.adminApi
        .generateHPCSV(mStart, mEnd, this._queryHP.stores)
        .then(res => {
          let blob = new Blob([res], { type: 'text/csv' });
          FileSaver.saveAs(blob, 'hp-resume.csv');
          this._exportHP = false;
          this._generating = false;
          this._fileToExtract = '';
          this._queryHP = { start: null, end: null, stores: '' };
        })
        .catch(err => {
          if (err.error.text) {
            let blob = new Blob([err.error.text], {
              type: 'text/csv',
            });
            FileSaver.saveAs(blob, 'hp-resume.csv');
            this._exportHP = false;
            this._generating = false;
            this._fileToExtract = '';
            this._queryHP = { start: null, end: null, stores: '' };
          }
        });
    }

    if (this._exportNPS) {
      this.adminApi
        .generateNPSCSV(this._fileToExtract)
        .then((res: any) => {
          console.dir(res);
          let blob = new Blob([res], { type: 'text/csv' });
          FileSaver.saveAs(blob, 'nps-resume.csv');
          this._exportNPS = false;
          this._generating = false;
          this._fileToExtract = '';
        })
        .catch(err => {
          if (err.error.text) {
            let blob = new Blob([err.error.text], {
              type: 'text/csv',
            });
            FileSaver.saveAs(blob, 'nps-resume.csv');
            this._exportNPS = false;
            this._generating = false;
            this._fileToExtract = '';
          }
        });
    }
  }

  checkDates() {
    if (this._exportNPS) {
      return;
    }

    setTimeout(() => {
      this.checkDates();
    }, 250);

    if (!this._queryHP.start || !this._queryHP.end) {
      this._canGenerate = false;
      return;
    }

    if (this._queryHP.start && this._queryHP.end) {
      let start = moment(
        this._queryHP.start.year +
          '-' +
          this._queryHP.start.month +
          '-' +
          this._queryHP.start.day,
        'YYYY-M-DD',
      ).startOf('day');
      let end = moment(
        this._queryHP.end.year +
          '-' +
          this._queryHP.end.month +
          '-' +
          this._queryHP.end.day,
        'YYYY-M-DD',
      ).endOf('day');

      if (start >= end) {
        this._errorDate = true;
      } else {
        this._errorDate = false;
        this._canGenerate = true;
      }
    }
  }

  selectLastMonth() {
    let lastMonth = moment().subtract(1, 'month');

    this._queryHP = {
      start: {
        day: lastMonth.startOf('month').format('DD'),
        month: lastMonth.startOf('month').format('M'),
        year: lastMonth.startOf('month').format('YYYY'),
      },
      end: {
        day: lastMonth.endOf('month').format('DD'),
        month: lastMonth.endOf('month').format('M'),
        year: lastMonth.endOf('month').format('YYYY'),
      },
      stores: this._queryHP.stores,
    };
    console.log('Last month: %o', lastMonth);
    console.log('_queryHP: %o', this._queryHP);

    this._hpExtractLastMonth = true;
    this._hpExtractLastYear = false;
  }

  selectLastYear() {
    let lastYear = moment().subtract(1, 'year');

    this._queryHP = {
      start: {
        day: lastYear.startOf('month').format('DD'),
        month: lastYear.startOf('month').format('M'),
        year: lastYear.startOf('month').format('YYYY'),
      },
      end: {
        day: lastYear.endOf('month').format('DD'),
        month: lastYear.endOf('month').format('M'),
        year: lastYear.endOf('month').format('YYYY'),
      },
      stores: this._queryHP.stores,
    };

    console.log('Last year: %o', lastYear);
    console.log('_queryHP: %o', this._queryHP);

    this._hpExtractLastYear = true;
    this._hpExtractLastMonth = false;
  }
}

import { Output, EventEmitter, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerListComponent implements OnInit {
  public _customerList: any;
  public _currentCutomer: string;

  public _search: any;
  public _currentSearch: any;

  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  public _totalCustomers: number;

  @Output() onTraceCustomerAction = new EventEmitter<string>();

  // @Input()
  // get promotionId() {
  //   return this._promoId;
  // }

  // set promotionId(promoId: string) {
  //   this._promoId = promoId;
  // }

  constructor(private adminApi: AdminApiService) {
    this._currentSearch = {};
    this._currentPage = 0;
    this._itemsByPage = 20;
    this._totalPages = 1;
    this._totalCustomers = 0;
    this._search = {};
  }

  ngOnInit() {
    this._currentSearch = {};
    this._currentPage = 0;
    this._itemsByPage = 20;
    this._totalPages = 1;
    this._search = {};
    this._totalCustomers = 0;
    this.loadCustomers();
  }

  loadCustomers() {
    this.adminApi
      .listCustomers(this._currentPage, this._itemsByPage, {})
      .then((customers: any) => {
        this._customerList = customers.items;
        this._totalCustomers = customers.total;
        this._totalPages = Math.floor(customers.total / this._itemsByPage) + 1;
      });
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadCustomers();
  }

  traceCustomer( cusId: string ){
    this.onTraceCustomerAction.emit(cusId);
  }
}

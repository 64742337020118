import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-alert-tracking-graph',
  templateUrl: './alert-tracking-graph.component.html',
  styleUrls: ['./alert-tracking-graph.component.scss'],
})
export class AlertTrackingGraphComponent implements OnInit, OnChanges {
  public _graphData: any;
  public _rawData: any;
  public _defaultGraphConfig: any;

  public storeName: string;
  public storeCode: string;

  public closed: number;
  public inprogress: number;
  public pending: number;

  @Input()
  set data(data: any) {
    this._rawData = data;
  }

  get data() {
    return this._rawData;
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges(_changes: SimpleChanges) {
    if (
      _changes.data &&
      _changes.data.currentValue &&
      _changes.data.currentValue.stats
    ) {
      this.storeCode = this._rawData.stats.store.code;
      this.storeName = this._rawData.stats.store.name;

      this.closed = this._rawData.stats.closed;
      this.pending = this._rawData.stats.pending;
      this.inprogress = this._rawData.stats.inprogress;

      this.initGraph();
    }
  }

  initGraph() {
    this._rawData = [];
    this._graphData = [
      {
        key: 'Cerrado',
        y: this.closed,
      },
      {
        key: 'En curso',
        y: this.inprogress,
      },
      {
        key: 'Pendiente',
        y: this.pending,
      },
    ];

    this._defaultGraphConfig = {
      chart: {
        type: 'pieChart',
        height: 250,
        legend: {
          margin: {
            top: 5,
            right: 35,
            bottom: 5,
            left: 0,
          },
        },
        x: function(d) {
          return d.key;
        },
        y: function(d) {
          return d.y;
        },
        showLabels: true,
        duration: 500,
        labelThreshold: 0.01,
        labelSunbeamLayout: true,
      },
    };
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-everest-list',
  templateUrl: './everest-list.component.html',
  styleUrls: ['./everest-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EverestListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-stores-list',
  templateUrl: './admin-stores-list.component.html',
  styleUrls: ['./admin-stores-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminStoresListComponent implements OnInit {
  @Output() onSelectedStore = new EventEmitter<string>();

  public storeList: any;
  public _totalStores: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this.storeList = [];
    this._totalStores = 0;
    this._currentPage = 0;
    this._itemsByPage = 15;
    this._totalPages = 1;
    this.loadStores();
  }

  updatePage( page: number ) {
    this._currentPage = page;
    this.loadStores();
  }

  loadStores() {
    this.adminApi.listStores( this._currentPage, this._itemsByPage, {} ).then((res: any) => {
      this.storeList = res.items;
      this._totalStores = res.total;
      this._totalPages = Math.floor(res.total / this._itemsByPage) + 1;
    });
  }

  edit( storeId: string ) {
    console.log('Editing store %c%s', 'color: yellow', storeId );
    this.onSelectedStore.emit(storeId);
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-everest-page',
  templateUrl: './everest-page.component.html',
  styleUrls: ['./everest-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EverestPageComponent implements OnInit {
  public _currentTab: string;
  public _previousEverest: any;
  public _currentEverest: any;

  constructor() {}

  ngOnInit() {
    this._currentTab = 'resume';
    this._currentEverest = {};
    this._previousEverest = [];
  }

  setTab(name: string) {
    if (this._currentTab === name) {
      return;
    }

    if (name === 'previous' && this._previousEverest.length <= 0) {
      return;
    }

    this._currentTab = name;
  }
}

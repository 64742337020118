import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-npspromotion-edit',
  templateUrl: './npspromotion-edit.component.html',
  styleUrls: ['./npspromotion-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NpspromotionEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "objectDump"
})
export class ObjectDumpPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return JSON.stringify(value);
  }
}

import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import * as _ from 'underscore';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TablePaginationComponent implements OnInit {
  public _currentPage: number;
  public _itemsByPage: number;
  public _totalPages: number;
  public pages: any;

  @Output() onPageChanged = new EventEmitter<number>();

  @Input()
  set totalPages(totalPages: number) {
    this._totalPages = totalPages;
    this.updatePager();
  }
  get totalPages(): number {
    return this._totalPages;
  }

  @Input()
  set itemsByPage(itemsByPage: number) {
    this._itemsByPage = itemsByPage;
    this.updatePager();
  }
  get itemsByPage(): number {
    return this._itemsByPage;
  }

  @Input()
  set currentPage(currentPage: number) {
    this._currentPage = currentPage;
    this.updatePager();
  }
  get currentPage(): number {
    return this._currentPage;
  }

  constructor() {}

  ngOnInit() {}

  updatePager() {
    this.pages = [];
    let x = 0;
    let min = 0;
    let max = 4;

    while (++x <= this._totalPages) {
      if (this._totalPages > 5) {
        if (this._totalPages - this._currentPage <= 2 && x >= this._totalPages - 4) {
          this.pages.push(x);
        } else if (x >= this._currentPage - 1 && this.pages.length <= 4) {
          this.pages.push(x);
        }
      } else {
        if ( x <= 5 ) {
          this.pages.push(x);
        }
      }
    }

  }

  setCurrentPage(page: number) {
    this._currentPage = page - 1;
    this.updatePager();
    this.onPageChanged.emit(this._currentPage);
  }

  goToPrev() {
    if (this._currentPage >= 1) {
      this._currentPage--;
      this.updatePager();
      this.onPageChanged.emit(this._currentPage);
    }
  }

  goToNext() {
    if (this._currentPage < this._totalPages - 1) {
      this._currentPage++;
      this.updatePager();
      this.onPageChanged.emit(this._currentPage);
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-hpdashboard-resume',
  templateUrl: './hpdashboard-resume.component.html',
  styleUrls: ['./hpdashboard-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HpdashboardResumeComponent implements OnInit {
  public _lastHP: any;
  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this.loadLastHappyPoints();
  }

  loadLastHappyPoints() {
    this.adminApi
      .getDashboardLast5HP()
      .then((result: any) => {
        this._lastHP = result;
      })
      .catch(error => {
        console.dir(error);
      });
  }
}

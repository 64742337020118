import { AdminApiService } from './../admin-api.service';
import { Router } from '@angular/router';
import { BqAuthService } from './../bq-auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
  public showNavbar: boolean;
  public _isDeveloper: boolean;
  public _isAdmin: boolean;
  public _appStatus: any;

  constructor(
    private _bqAuth: BqAuthService,
    private router: Router,
    private adminApi: AdminApiService,
  ) {
    this.showNavbar = false;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this._bqAuth.isLogged() === true) {
          this.showNavbar = true;
        } else {
          this.showNavbar = false;
        }
      }
      if (this._bqAuth.isDeveloper() === true) {
        this._isDeveloper = true;
      } else {
        this._isDeveloper = false;
      }

      if (this._bqAuth.isAdmin() === true) {
        this._isAdmin = true;
      } else {
        this._isAdmin = false;
      }
    });
  }

  ngOnInit() {
    if (this._bqAuth.isLogged() === true) {
      this.showNavbar = true;
    } else {
      this.showNavbar = false;
    }

    if (this._bqAuth.isDeveloper() === true) {
      this._isDeveloper = true;
    } else {
      this._isDeveloper = false;
    }

    this.adminApi.getBackendStatus().subscribe(status => {
      this._appStatus = status;
    });
  }
}

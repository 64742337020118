import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef
} from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-user-edit-page",
  templateUrl: "./user-edit-page.component.html",
  styleUrls: ["./user-edit-page.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class UserEditPageComponent implements OnInit {
  public selectedUser: any;
  public _updateUserList: boolean;

  constructor(public toastr: ToastrService, vcr: ViewContainerRef) {}
  ngOnInit() {
    this._updateUserList = false;
  }

  userSelected(userId) {
    this.selectedUser = userId;
  }

  closeEditor(changed) {
    if (changed === true) {
      setTimeout(() => {
        this.toastr.success("Se ha guardado la nueva información del usuario.");
        this._updateUserList = true;
      }, 500);
    }
    this.selectedUser = null;
  }
}

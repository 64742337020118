import { environment } from "./../../environments/environment";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdminApiService } from "../admin-api.service";

import * as moment from "moment";

@Component({
  selector: "app-everest-resume",
  templateUrl: "./everest-resume.component.html",
  styleUrls: ["./everest-resume.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EverestResumeComponent implements OnInit {
  public __activeCampaign: any;
  public __everestList: any;
  public __remainingList: any;

  public queryMonth: string;
  public queryYear: string;

  constructor(private adminApi: AdminApiService) {
    this.__activeCampaign = null;
    this.__everestList = [];
    this.__remainingList = [];

    this.queryMonth = window.sessionStorage.getItem("EveresResumeQueryMonth");

    if (!this.queryMonth) {
      this.queryMonth = moment().format("MM");
    }

    this.queryYear = window.sessionStorage.getItem("EveresResumeQueryYear");
    if (!this.queryYear) {
      this.queryYear = moment().format("YYYY");
    }
  }

  ngOnInit() {
    this.loadActive();
  }

  updateList() {
    window.sessionStorage.setItem("EveresResumeQueryMonth", this.queryMonth);
    window.sessionStorage.setItem("EveresResumeQueryYear", this.queryYear);

    this.adminApi
      .getEverestResults(this.queryMonth, this.queryYear)
      .then((results: any) => {
        this.__everestList = results.everests;
        this.__remainingList = results.remaining;
      });
  }

  loadActive() {
    this.adminApi.getEverestActiveCampaign().then((active: any) => {
      if (!active) {
        return;
      }
      this.__activeCampaign = active;
      this.__activeCampaign.url =
        environment.mysteryURL + "/?everest=" + active.token;

      this.adminApi
        .getEverestResults(this.queryMonth, this.queryYear)
        .then((results: any) => {
          this.__everestList = results.everests;
          this.__remainingList = results.remaining;
        });
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { MailTemplate } from "./bqdata-model";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { environment } from "../environments/environment";

@Injectable()
export class BaseApiService {
  constructor(private _http: HttpClient) {}

  checkWelcome() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/")
        .toPromise()
        .then((res: any) => {
          resolve(res.status);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getDeviceList() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/device/list")
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
}

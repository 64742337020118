import { HttpClient } from "@angular/common/http";
import { User } from "./bqdata-model";
import { Injectable } from "@angular/core";
import { Response, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { environment } from "../environments/environment";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";

@Injectable()
export class BqDeveloperAuthService implements CanActivate {
  private _isLogged: boolean;
  private _userData: User;

  constructor(private _http: HttpClient, private router: Router) {
    this._isLogged = false;
    this._userData = new User();
  }

  log(text: string, data: any = {}) {
    console.log(
      "[%cDEVEL AUTH%c][%o] %c%s",
      "color: red",
      "",
      data,
      "color: gray",
      text
    );
  }

  login(username: string, password: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/login", {
          username: username,
          password: password
        })
        .toPromise()
        .then((loginRes: User) => {
          let uData = loginRes;
          this._userData = uData;
          this._isLogged = true;
          window.sessionStorage.setItem("BQT.token", uData.token);
          window.sessionStorage.setItem("BQT.cudat", JSON.stringify(uData));
          resolve(uData);
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }

  canActivate() {
    let uToken = window.sessionStorage.getItem("BQT.token");
    let uData = window.sessionStorage.getItem("BQT.cudat");

    if (!uToken || !uData) {
      this.log("user is not logged!");
      this._userData = new User();
      this._isLogged = false;

      this.router.navigate(["/login"]);
      return false;
    } else {
      this.log("user is logged!");
      this._isLogged = Boolean(window.sessionStorage.getItem("BQT.token"));
      this._userData = JSON.parse(window.sessionStorage.getItem("BQT.cudat"));

      if (!this._userData.isDeveloper) {
        this.log("User is not allowed here!");
        return false;
      } else {
        return true;
      }
    }
  }

  getUserData() {
    return this._userData;
  }

  isLogged() {
    return this.canActivate();
  }
}

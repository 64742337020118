import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef,
  Output,
  EventEmitter
} from "@angular/core";

import { MailTemplate } from "../bqdata-model";
import { AdminApiService } from "../admin-api.service";
import { ToastrService } from "ngx-toastr";
import { OutputType } from "@angular/core/src/view";

@Component({
  selector: "app-mail-template-new",
  templateUrl: "./mail-template-new.component.html",
  styleUrls: ["./mail-template-new.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class MailTemplateNewComponent implements OnInit {
  public _currentTemplate: MailTemplate;

  @Output()
  closeEditor = new EventEmitter<Boolean>();

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this._currentTemplate = new MailTemplate();
  }

  createNewTemplate() {
    console.log("Creating new template.");
    console.dir(this._currentTemplate);

    if (!this._currentTemplate.code || this._currentTemplate.code === "") {
      this.toastr.warning("Debe especificar un código para la plantilla.");
      return;
    }
    if (!this._currentTemplate.name || this._currentTemplate.name === "") {
      this.toastr.warning("Debe especificar un nombre para la plantilla.");
      return;
    }
    if (
      !this._currentTemplate.internalId ||
      this._currentTemplate.internalId === ""
    ) {
      this.toastr.warning("Debe especificar un ID interno para la plantilla.");
      return;
    }
    if (
      !this._currentTemplate.description ||
      this._currentTemplate.description === ""
    ) {
      this.toastr.warning(
        "Debe especificar una descripción para la plantilla."
      );
      return;
    }

    this.adminApi
      .createEmailTemplate(this._currentTemplate)
      .then(result => {
        this.closeEditor.emit(true);
        console.dir(result);
      })
      .catch(error => {
        this.toastr.error(error._body, "Lo sentimos ha habido un error");
        console.dir(error);
      });
  }

  goBack() {
    this.closeEditor.emit(false);
  }
}

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdminApiService } from "../admin-api.service";

@Component({
  selector: "app-device-manager-page",
  templateUrl: "./device-manager-page.component.html",
  styleUrls: ["./device-manager-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DeviceManagerPageComponent implements OnInit {
  public filter: any;
  public _storeList: any;
  public _selectedDevice: string;

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.filter = { state: "", store: "" };
    this.adminApi.listStoresSelect().then((stores: any) => {
      this._storeList = stores.items;
    });
  }

  showDeviceDetails(deviceId: string) {
    console.log("Showing device details: " + deviceId);
    this._selectedDevice = deviceId;
  }
}

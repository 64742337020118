import { ToastrService } from "ngx-toastr";
import {
  ViewContainerRef,
  Component,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { AdminApiService } from "../admin-api.service";

@Component({
  selector: "app-everest-profiles",
  templateUrl: "./everest-profiles.component.html",
  styleUrls: ["./everest-profiles.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EverestProfilesComponent implements OnInit {
  public _profileList: any;
  public _currentProfile: any;

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef
  ) {
    this.clearCurrentProfile();
  }

  ngOnInit() {
    this.loadProfiles();
  }

  loadProfiles() {
    this.adminApi
      .getEverestProfiles()
      .then(profiles => {
        this._profileList = profiles;
      })
      .catch(err => {
        console.dir(err);
      });
  }

  clearCurrentProfile() {
    this._currentProfile = {
      _id: null,
      name: "",
      code: ""
    };
  }

  newProfile() {
    this.clearCurrentProfile();
    this._currentProfile._id = "new";

    this._currentProfile.code = this._profileList.length + 1;
  }

  saveProfile() {
    if (!this._currentProfile.code || !this._currentProfile.name) {
      this.toastr.error(
        "Debe especificar un codigo y un nombre para el perfil."
      );
      return;
    }

    if (this._currentProfile._id !== "new") {
      this.adminApi
        .editEverestProfile(this._currentProfile._id, this._currentProfile)
        .then(r => {
          this.toastr.success("Se ha guardado correctamente el nuevo perfil!");
          this.loadProfiles();
          this.clearCurrentProfile();
        })
        .catch(err => {
          this.toastr.error(
            err.error
              ? err.error
              : "Error desconocido, vuelva a intentarlo, si el fallo continua contacte con soporte.",
            "Ha habido un error al guardar el perfil."
          );
        });
    } else {
      this.adminApi
        .newEverestProfile({
          name: this._currentProfile.name,
          code: this._currentProfile.code
        })
        .then(r => {
          this.toastr.success("Se ha guardado correctamente el nuevo perfil!");
          this.loadProfiles();
          this.clearCurrentProfile();
        })
        .catch(err => {
          this.toastr.error(
            err.error
              ? err.error
              : "Error desconocido, vuelva a intentarlo, si el fallo continua contacte con soporte.",
            "Ha habido un error al guardar el perfil."
          );
        });
    }
  }
}

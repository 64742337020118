import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

declare let d3: any;

@Component({
  selector: "app-admin-stats",
  templateUrl: "./admin-stats.component.html",
  styleUrls: [
    "./admin-stats.component.css",
    "../../../node_modules/nvd3/build/nv.d3.css"
  ],
  encapsulation: ViewEncapsulation.None
})
export class AdminStatsComponent implements OnInit {
  public _stats: any;
  public _prevStats: any;
  public _statsRefresh: number; // in minutes
  public _counterTime: number; // in seconds

  public uCount: number;
  public dCount: number;
  public tCount: number;
  public aCount: number;
  public hpCount: number;
  public npsCount: number;

  constructor(private adminApi: AdminApiService) {
    this._statsRefresh = 0.7;
    this._counterTime = 1.5;

    this.uCount = 0;
    this.aCount = 0;
    this.tCount = 0;
    this.dCount = 0;
    this.hpCount = 0;
    this.npsCount = 0;

    this._stats = {
      users: 0,
      devices: 0,
      stores: 0,
      surveys: 0,
      npsSurveys: 0,
      qrfree: 0,
      alerts: { danger: 0, warning: 0 }
    };
    this._prevStats = { qrfree: 0,  users: 0, devices: 0, stores: 0, surveys: 0, npsSurveys: 0, alerts: { danger: 0, warning: 0 } };
  }

  ngOnInit() {
    this.loadStats();
  }

  loadStats() {
    this.adminApi.getDashboardStats().then(stats => {
      this._prevStats = this._stats;
      this._stats = stats;
      setTimeout(() => this.loadStats(), this._statsRefresh * 60 * 1000);
    });
  }
}

import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const __appVersion = '1.0';

if (environment.production) {
  enableProdMode();
}

console.log('[%cBQApp%c] %c' + __appVersion + '%c-%c' + environment.envName , 'color: red', '', 'color: yellow', '', 'color: orange' );

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

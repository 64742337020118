import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-promotions-list',
  templateUrl: './promotions-list.component.html',
  styleUrls: ['./promotions-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionsListComponent implements OnInit {
  public _promotionList: any;

  @Output() onEditPromotion = new EventEmitter<string>();

  constructor( private adminApi: AdminApiService ) { }

  ngOnInit() {
    this.loadPromotions();
  }

  edit( promoID: string ) {
    this.onEditPromotion.emit(promoID);
  }

  loadPromotions() {
    this.adminApi.listPromotions()
      .then((promotions) => {
        this._promotionList = promotions;
      });
  }

}

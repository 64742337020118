import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-device-dashboard-resume',
  templateUrl: './device-dashboard-resume.component.html',
  styleUrls: ['./device-dashboard-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceDashboardResumeComponent implements OnInit {
  public _devicesInAlarm: boolean;
  public _devicesLoading: boolean;

  public _resumeData: any;

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this._devicesInAlarm = false;
    this._devicesLoading = true;

    setTimeout(() => {
      this.loadDeviceResume();
    }, 500);
  }

  loadDeviceResume() {
    this.adminApi
      .getDashboardDeviceResume()
      .then((resume: any) => {
        this._resumeData = resume.stats;
        this._devicesLoading = false;
        if (this._resumeData.alarm >= 1) {
          this._devicesInAlarm = true;
        } else {
          this._devicesInAlarm = false;
        }
      })
      .catch(error => {
        console.dir(error);
      });
  }

}

import { AdminApiService } from './../admin-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-queue-page',
  templateUrl: './mail-queue-page.component.html',
  styleUrls: ['./mail-queue-page.component.scss'],
})
export class MailQueuePageComponent implements OnInit {
  public _queueData: any;

  public aCount: number;
  public bCount: number;
  public cCount: number;

  public _emailSearch: String = '';
  public _searchResult: any;

  public detailMode: boolean;
  public _details: any;

  constructor(private _adminApi: AdminApiService) {}

  ngOnInit() {
    this._queueData = null;
    this.detailMode = false;

    this.loadData();
    this.reload();
  }

  loadData() {
    this._adminApi.getEmailQueue().subscribe(queueData => {
      this._queueData = queueData;
    });
  }

  reload() {
    // setTimeout(() => {
    //   if (!this.detailMode) {
    //     this.loadData();
    //     this.reload();
    //   }
    // }, 15 * 1000);
  }

  showDetails(details: any) {
    this.detailMode = true;
    this._details = details;
  }

  hideDetails() {
    this.detailMode = false;
    this._details = null;
  }

  doSearch(event) {
    if (event.type === 'keydown' && event.keyCode && event.keyCode !== 13) {
      return;
    }

    this._adminApi
      .searchEmailQueue(this._emailSearch)
      .subscribe((result: any) => {
        this._queueData = null;
        this._searchResult = result;
      });
  }
}

import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-admin-store-edit',
  templateUrl: './admin-store-edit.component.html',
  styleUrls: ['./admin-store-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminStoreEditComponent implements OnInit, OnChanges {
  public _storeId = '';
  public _storeData = null;

  public _storeDivisions = [];
  public _storeDevices = [];

  @Output()
  onStoreEdit = new EventEmitter<boolean>();

  @Input()
  set storeId(storeId: string) {
    this._storeId = storeId;
  }
  get storeId(): string {
    return this._storeId;
  }

  constructor(private adminApi: AdminApiService) {
    this._storeDevices = [];
    this._storeDivisions = [];
  }

  ngOnInit() {
    this._storeData = {
      code: '',
      name: '',
      phone: '',
      mobile: '',
      email: '',
      division: null,
    };

    this.adminApi.listStoreDivisions().subscribe(data => {
      this._storeDivisions = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._storeId || this._storeId === 'new') {
      return;
    }
    console.log('New store loaded %c%s', 'color: green', this._storeId);

    this.adminApi
      .storeDetail(this._storeId)
      .then((res: any) => {
        this._storeData = res.store;
        this._storeDevices = res.devices;
        if (this._storeData.division && this._storeData.division._id) {
          this._storeData.division = this._storeData.division._id;
        }
      })
      .catch(err => {
        console.dir(err);
      });
  }

  goBack() {
    this.onStoreEdit.emit(false);
  }

  saveStore() {
    this.adminApi
      .storeEdit(this._storeId, this._storeData)
      .then(res => {
        this.onStoreEdit.emit(true);
      })
      .catch(err => {
        console.dir(err);
      });
  }
}

import { AdminApiService } from './../admin-api.service';
import { Router } from '@angular/router';
import { BqAuthService } from './../bq-auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent implements OnInit {
  public username: string;
  public password: string;
  public backStatus: any;
  public maintenanceMode: boolean;
  public __waitForLoad: boolean;

  constructor(
    private _bqAuth: BqAuthService,
    private router: Router,
    private adminApi: AdminApiService,
  ) {
    this.__waitForLoad = true;
  }

  ngOnInit() {
    this.maintenanceMode = false;

    const lmm = window.sessionStorage.getItem('BQ360::__devMode');

    this.adminApi.getBackendStatus().subscribe(status => {
      this.backStatus = status;

      setTimeout(() => {
        this.__waitForLoad = false;
      }, 750);

      if (status && status.maintenance && status.maintenance === true) {
        this.maintenanceMode = true;
      }

      if (lmm) {
        this.maintenanceMode = false;
      }
    });

    if (lmm) {
      this.maintenanceMode = false;
    }

    if (this._bqAuth.isLogged()) {
      this.router.navigate(['/']);
    }
  }

  login() {
    this._bqAuth
      .login(this.username, this.password)
      .then(res => {
        this.router.navigate(['/']);
      })
      .catch(err => {});
  }
}

import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyDetailsComponent implements OnInit, OnChanges {
  public _surveyId: string;
  public _survey: any;

  @Output() onCloseEdit = new EventEmitter<any>();

  @Input()
    get survey(){
      return this._surveyId;
    }

    set survey( suid: string ) {
      this._surveyId = suid;
    }

  constructor( private adminApi: AdminApiService ) {
  }

  ngOnInit() {
  }

  closeEditor() {
    this.onCloseEdit.emit(true);
  }

  ngOnChanges() {
    this.adminApi.surveyDetails( this._surveyId )
      .then((survey) => {
        this._survey = survey;
      })
      .catch((err) => {
        console.dir(err);
      });
  }
}

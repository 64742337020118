import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AdminApiService } from "./../admin-api.service";
import { Component, OnInit } from "@angular/core";

import * as _ from "lodash";

@Component({
  selector: "app-device-assign-page",
  templateUrl: "./device-assign-page.component.html",
  styleUrls: ["./device-assign-page.component.scss"]
})
export class DeviceAssignPageComponent implements OnInit {
  public _selectedDevice: string;
  public _selectedStore: string;
  public _selectedDeviceData: any;
  public _selectedDeviceSurveys: any;

  public _fromDate: any;
  public _dontMoveHP: boolean;
  public _validated: boolean;

  public _assignCheckData: any;

  public _devices: any;
  public _stores: any;

  constructor(
    private _adminApi: AdminApiService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this._devices = [];
    this.loadDevices();

    this._adminApi.getAllStores().subscribe(stores => {
      this._stores = stores.items;
    });
  }

  loadDevices() {
    this._adminApi.getDeviceList().subscribe(devices => {
      this._devices = _.sortBy(devices.items, "name");
    });
  }

  deviceChanged() {
    if (!this._selectedDevice) {
      return;
    }

    this._selectedDeviceSurveys = [];
    this._dontMoveHP = false;
    this._selectedStore = null;
    this._fromDate = null;
    this._assignCheckData = null;

    this._adminApi.getDeviceDetails(this._selectedDevice).subscribe(details => {
      this._selectedDeviceData = details.device;
      this._selectedDeviceSurveys = details.surveys;
    });
  }

  storeChanged() {
    this._dontMoveHP = false;
    this._fromDate = null;
    this._assignCheckData = null;
  }

  dateChanged() {
    this._dontMoveHP = false;
    this._assignCheckData = null;
  }

  check() {
    this._adminApi
      .checkDeviceAssignation(
        this._selectedDevice,
        this._selectedDeviceData.store
          ? this._selectedDeviceData.store._id
          : null,
        this._selectedStore,
        this._fromDate
          ? this._fromDate.year +
            "-" +
            this._fromDate.month +
            "-" +
            this._fromDate.day
          : ""
      )
      .subscribe(assignCheck => {
        this._assignCheckData = assignCheck;
      });
  }

  validate() {
    this._adminApi
      .deviceReassign(
        this._selectedDevice,
        this._selectedDeviceData.store
          ? this._selectedDeviceData.store._id
          : null,
        this._selectedStore,
        this._fromDate
          ? this._fromDate.year +
            "-" +
            this._fromDate.month +
            "-" +
            this._fromDate.day
          : "",
        { dontMove: this._dontMoveHP }
      )
      .subscribe(assignCheck => {
        this.toastr.success("Se ha actualizado la tablet!");
        this.router.navigate(["/admin"]);
      });
  }
}

import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";

import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  log(text: string) {
    // console.log('[%cHttpInjector%c] %s', 'color: yellow', '', text );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      window.sessionStorage.removeItem("BQT.token");
      window.sessionStorage.removeItem("BQT.cudat");
      this.router.navigate(["/login"]);
      return Observable.of(err.message);
    }
    return Observable.throw(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.log("Intercepting request: [" + req.method + "] " + req.url);
    // Clone the request to add the new header
    const uToken = window.sessionStorage.getItem("BQT.token");
    const clonedRequest = req.clone({
      headers: req.headers.append("AAT", uToken || "")
    });
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest).catch(x => this.handleAuthError(x));
  }
}

import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-surveys-page",
  templateUrl: "./surveys-page.component.html",
  styleUrls: ["./surveys-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SurveysPageComponent implements OnInit {
  public _selectedSurvey: string;

  constructor() {}

  ngOnInit() {}

  showDetails( surveyId: string ) {
    console.log('Showing survey details for ' + surveyId );
    this._selectedSurvey = surveyId;
  }

  showList() {
    this._selectedSurvey = null;
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-npssurveys-page',
  templateUrl: './npssurveys-page.component.html',
  styleUrls: ['./npssurveys-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NpssurveysPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

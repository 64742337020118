import { TestPageComponent } from "./test-page/test-page.component";
import { UserProfilePageComponent } from "./user-profile-page/user-profile-page.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DashboardPageComponent } from "./dashboard-page/dashboard-page.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { BqAuthService } from "./bq-auth.service";
import { CsvUploadPageComponent } from "./csv-upload-page/csv-upload-page.component";

const appRoutes: Routes = [
  { path: "", component: DashboardPageComponent, canActivate: [BqAuthService] },
  {
    path: "me",
    component: UserProfilePageComponent,
    canActivate: [BqAuthService]
  },
  // Public access
  { path: "login", component: LoginPageComponent },
  { path: "test", component: TestPageComponent },
  {
    path: "csv-upload",
    component: CsvUploadPageComponent,
    canActivate: [BqAuthService]
  },

  // default when route is unknown
  { path: "**", component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Input, Output, EventEmitter, Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerDetailComponent implements OnInit, OnChanges {
  private _customerId: string;
  public _customer: any;

  @Input() set customerId( cusId: string ){
    this._customerId = cusId;
  }
  get customerId() {
    return this._customerId;
  }

  @Output() onCloseEditor = new EventEmitter<boolean>();

  constructor( private adminApi: AdminApiService ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.adminApi.getCustomerDetails( this._customerId )
      .then((customer) => {
        this._customer = customer;
      });
  }
}

import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-store-device-edit-page',
  templateUrl: './store-device-edit-page.component.html',
  styleUrls: ['./store-device-edit-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class StoreDeviceEditPageComponent implements OnInit {
  public _selectedStore: any;
  public _selectedDevice: any;
  public _isDeveloper: Boolean = false;

  constructor(public toastr: ToastrService) {}

  ngOnInit() {
    this._selectedDevice = null;
    this._selectedStore = null;
  }

  deviceSelected(device_id) {
    this._selectedDevice = device_id;
  }

  storeSelected(store_id) {
    this._selectedStore = store_id;
  }

  storeEditClose(edited) {
    if (edited) {
      this.toastr.success('Se ha guardado la información de la tienda.');
    }
    this._selectedDevice = null;
    this._selectedStore = null;
  }

  deviceEditClose(edited) {
    if (edited) {
      this.toastr.success('Se ha guardado la información del dispositivo.');
    }
    this._selectedDevice = null;
    this._selectedStore = null;
  }

  newStore() {
    this._selectedStore = 'new';
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: "app-mail-editor-page",
  templateUrl: "./mail-editor-page.component.html",
  styleUrls: ["./mail-editor-page.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class MailEditorPageComponent implements OnInit {
  public _showNewEditor: boolean;
  public _showEditor: boolean;
  public _currentTpl: string;

  constructor() {}

  ngOnInit() {
    this._showEditor = false;
    this._showNewEditor = false;
    this._currentTpl = '';
  }

  showNewEditor() {
    this._showEditor = false;
    this._showNewEditor = true;
  }

  showList() {
    this._showEditor = false;
    this._showNewEditor = false;
  }

  editTemplate( tplId: string ) {
    this._currentTpl = tplId;
    this._showEditor = true;
  }
}

import { AdminApiService } from "./../admin-api.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "app-nps-mailing-trace",
  templateUrl: "./nps-mailing-trace.component.html",
  styleUrls: ["./nps-mailing-trace.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NpsMailingTraceComponent implements OnInit, OnChanges {
  private __traceEmailId: string;
  public __currentEmailData: any;

  @Input()
  get traceEmailId() {
    return this.__traceEmailId;
  }
  set traceEmailId(traceId: string) {
    this.__traceEmailId = traceId;
  }

  @Output() onTraceEmailEnded = new EventEmitter<boolean>();

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.loadEmailTrace();
  }

  closePanel() {
    this.onTraceEmailEnded.emit(true);
  }

  loadEmailTrace() {
    this.adminApi
      .traceSurveyEmail(this.__traceEmailId)
      .then(sRes => {
        this.__currentEmailData = sRes;
      })
      .catch(sErr => {
        console.dir(sErr);
      });
  }
}

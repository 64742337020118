import { environment } from './../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from './../admin-api.service';
import { Component, OnInit } from '@angular/core';

export class HPConfig {
  public voucherAmount: Number = 0;
  public welcomeText: String = '';
  public goodbyeText: String = '';
  public footerText: String = '';
  public emailText: String = '';
}

export class SurveyDefinition {
  public _id: String = '';
  public name: String = '';
  public internalNotes: String = '';
  public active: Boolean = true;
}

export class SurveyDefinitionStep {
  public _id: String = '';
  public order: Number = 0;
  public name: String = 'New step';
  public text: String = '';
  public internalNotes: String = '';
  public active: Boolean = true;
  public type: String = '';
}

export class SurveyStepItem {
  public text: String = '';
  public name: String = '';
}

@Component({
  selector: 'app-happy-point-config',
  templateUrl: './happy-point-config.component.html',
  styleUrls: ['./happy-point-config.component.scss'],
})
export class HappyPointConfigComponent implements OnInit {
  public config: HPConfig;
  public surveyDefinition: SurveyDefinition;
  public _currentStep: SurveyDefinitionStep = null;
  public surveySteps: SurveyDefinitionStep[] = [];
  public _clientTag: any;

  constructor(
    private adminApi: AdminApiService,
    private toastr: ToastrService,
  ) {
    this.config = new HPConfig();
  }

  ngOnInit() {
    this.adminApi.getBackendStatus().subscribe(status => {
      this._clientTag = status.ctg;
    });

    this.adminApi.getHPConfig().subscribe((config: HPConfig) => {
      this.config = config;
    });

    this.adminApi
      .getSurveyDefinition()
      .subscribe((surveyDefinition: SurveyDefinition) => {
        this.surveyDefinition = surveyDefinition;
        this.loadSteps();
      });
  }

  loadSteps() {
    this.adminApi
      .getSurveyDefinitionSteps(this.surveyDefinition)
      .subscribe((surveySteps: SurveyDefinitionStep[]) => {
        this.surveySteps = surveySteps;
      });
  }

  addSurveyStep() {
    this._currentStep = new SurveyDefinitionStep();
  }

  save() {
    this.adminApi.setHPConfig(this.config).subscribe(
      (result: any) => {
        this.toastr.success('La configuración se ha guardado correctamente');
      },
      (error: any) => {
        this.toastr.error(
          'Ha habido un error al guardar la configuración, recarga la pagina y vuelve a intentarlo',
        );
      },
    );
  }

  cancelStep() {
    this._currentStep = null;
  }

  checkDuplicatedOrder(step: SurveyDefinitionStep): Boolean {
    for (let element of this.surveySteps) {
      if (element.order === step.order) {
        return true;
      }
    }
    return false;
  }

  saveStep() {
    if (this._currentStep.order <= 0) {
      this.toastr.error('Please set step order. Minimum value allowed 1');
      return;
    }

    if (this.checkDuplicatedOrder(this._currentStep)) {
      this.toastr.error('There is another step with same order.');
      return;
    }

    if (this._currentStep.name === '' || this._currentStep.text === '') {
      this.toastr.error(
        'Please fill all required fields, name, text, order...',
      );
      return;
    }

    this.adminApi
      .saveSurveyDefinitionSteps(this._currentStep, this.surveyDefinition)
      .subscribe((result: any) => {
        this.toastr.success('Step has been saved!');
        this.loadSteps();
        this._currentStep = null;
      });
  }

  editStep(step: SurveyDefinitionStep) {
    this._currentStep = step;
  }
}

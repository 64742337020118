import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-promotions-page',
  templateUrl: './promotions-page.component.html',
  styleUrls: ['./promotions-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionsPageComponent implements OnInit {
  public currentPromotion: string;

  constructor() { }

  ngOnInit() {
    this.currentPromotion = '';
  }

  closeEditor(){
    this.currentPromotion = '';
  }

  editPromotion( promoID: string ) {
    this.currentPromotion = promoID;
  }
}

import {
  SurveyDefinition,
  SurveyDefinitionStep,
} from './happy-point-config/happy-point-config.component';
import { FunnelRawData } from './funnel-editor-page/funnel-editor-page.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MailTemplate } from './bqdata-model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { environment } from '../environments/environment';

@Injectable()
export class AdminApiService {
  constructor(private _http: HttpClient) {}

  developerStats() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/developer-stats')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  cronLaunch(cronId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/launch-cron/' + cronId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  developerForcePromoClose(promoId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL + '/admin/dev-force-close-promotion/' + promoId,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  editUser(userId: string, userData: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/users/edit/' + userId, userData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listDevices(page: number, itemsByPage: number, search: any | {}) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/device?p=' + page + '&i=' + itemsByPage)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listUsers(page: number, itemsByPage: number, search: any | {}) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/users?p=' + page + '&i=' + itemsByPage)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  inactiveUsers() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/user-inactive')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
  activityUsersAdmin() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/user-activity-admin')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
  activityUsers() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/user-activity')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listGroups() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/users/groups')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listAllowedStoresNames(storeId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores/info-name/' + storeId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listAllowedStores(userId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/users/stores/' + userId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listDivisionsStores(zoneId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores/all-stores-divisions/' + zoneId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listAllStores() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores/list')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  userDetail(userId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/users/info/' + userId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  deviceDetail(deviceId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/device/info/' + deviceId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  generateUserPassword(userId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/users/generate-password/' + userId, {})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  updateDevice(deviceId: string, deviceDate: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/device/edit/' + deviceId, deviceDate)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listStores(page: number, itemsByPage: number, search: any | {}) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores?p=' + page + '&i=' + itemsByPage)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listStoresSelect() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores/list')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listStoreDivisions(): Observable<any> {
    return this._http.get(environment.API_URL + '/stores/divisions');
  }

  storeDetail(storeId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/stores/info/' + storeId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  storeEdit(storeId: string, storeData: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/stores/edit/' + storeId, storeData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  uploadCSV(type: string, filedata: any) {
    console.log('[API] Uploading %s CSV', type);
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/' + type + '/csv-upload', filedata)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEmailTemplates() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/email/templates')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEmailTemplate(tplId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/email/template/' + tplId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  createEmailTemplate(tplData: MailTemplate) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/admin/email/template', tplData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getAlertsModules() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/alerts/get-modules')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getAlerts(page: number, itemsByPage: number, search: any | {}) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL + '/admin/alerts?p=' + page + '&i=' + itemsByPage,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  markAlertsReviewed() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/admin/alerts/review-all', {})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getDashboardStats() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/stats')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getDeveloperStats() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/admin/hp-total-stats')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listSurveys(page: number, itemsByPage: number, search: any | {}) {
    let extraQuery = '';

    if (search.store && search.store !== 'any') {
      extraQuery += '&store=' + search.store;
    }

    if (search.device && search.device !== 'any') {
      extraQuery += '&device=' + search.device;
    }

    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL +
            '/survey/list?p=' +
            page +
            '&i=' +
            itemsByPage +
            extraQuery,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  surveyDetails(id: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/survey/details/' + id)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listPromotions() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/promotion/list')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  createPromotion(promoData) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/promotion/new', promoData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  loadPromotion(promoID) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/promotion/info/' + promoID)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  uploadQRCodes(csvData: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/promotion/upload-qrcodes', csvData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getPromotionCodes(
    promotion: string,
    page: number,
    itemsByPage: number,
    search: any | {},
  ) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL +
            '/promotion/list-codes/' +
            promotion +
            '?p=' +
            page +
            '&i=' +
            itemsByPage,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  uploadNPSCSV(csvData: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/nps/upload-csv', csvData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listNPSPromotions() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/promotion/nps/list')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getActiveNPS() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/promotion/nps/active')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  loadNPSPromotion(promoID) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/promotion/nps/info/' + promoID)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listNPSSurveys(page: number, itemsByPage: number, search: any) {
    let extraQuery = '';
    let isFirst = true;

    // tslint:disable-next-line:forin
    for (const param in search) {
      extraQuery += `&${param}=${search[param]}`;
    }

    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL +
            '/nps/list?p=' +
            page +
            '&i=' +
            itemsByPage +
            extraQuery,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  scheduleNPSPromotion(promoId: string, start: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/promotion/nps/schedule/' + promoId, {
          date: start,
        })
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  validateNPSPromotion(promoId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/promotion/nps/validate/' + promoId, {})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  traceSurveyEmail(surveyId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/nps/trace-survey-email/' + surveyId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  listCustomers(page: number, itemsByPage: number, search: any) {
    let extraQuery = '';

    if (search.promotion) {
      extraQuery = '&promotion=' + search.promotion;
    }

    let promise = new Promise((resolve, reject) => {
      this._http
        .get(
          environment.API_URL +
            '/customer/list?p=' +
            page +
            '&i=' +
            itemsByPage +
            extraQuery,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getCustomerDetails(customerId) {
    let extraQuery = '';

    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/customer/details/' + customerId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEverestProfiles() {
    let extraQuery = '';

    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/everest/profiles')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEverestActiveCampaign() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/everest/active-campaign')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEverestResults(month: any, year: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/everest/list/' + month + '/' + year)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEverestDetails(everestId: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/everest/details/' + everestId)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  createEverestCampaign(campaignData: any) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/everest/new-campaign', campaignData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  editEverestProfile(profileId: string, profileData: any) {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          environment.API_URL + '/everest/profile/edit/' + profileId,
          profileData,
        )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  newEverestProfile(profileData: any) {
    return new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/everest/profile/new', profileData)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDashboardDeviceResume() {
    return new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/device/stats')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDashboardNPSResume() {
    return new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/nps/stats')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDashboardLast5HP() {
    return new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + '/survey/last-five')
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  listNPSFiles() {
    return this._http.get(environment.API_URL + '/nps/file-list').toPromise();
  }

  generateNPSCSV(npsFile: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv');
    headers = headers.append('Content-Type', 'text/csv');
    return this._http
      .get(environment.API_URL + '/admin/get-nps-csv/' + npsFile, {
        headers: headers,
      })
      .toPromise();
  }

  generateHPCSV(start: number, end: number, store: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv');
    headers = headers.append('Content-Type', 'text/csv');
    return this._http
      .get(
        environment.API_URL +
          '/admin/get-hp-csv?s=' +
          start +
          '&e=' +
          end +
          '&t=' +
          store || '',
        {
          headers: headers,
        },
      )
      .toPromise();
  }

  getStoreCategories(): Observable<any> {
    return this._http.get(environment.API_URL + '/stores/categories');
  }

  getStoreDivisions(): Observable<any> {
    return this._http.get(environment.API_URL + '/stores/divisions');
  }

  newUser(userData: any): Observable<any> {
    return this._http.put(environment.API_URL + '/admin/new-user', {
      user: userData,
    });
  }
  updateEverestComments(everestId: string, comments: any): Observable<any> {
    return this._http.post(
      environment.API_URL + '/everest/update-comments/' + everestId,
      { comments: comments },
    );
  }

  validateEverest(everestId: string, comments: any): Observable<any> {
    return this._http.post(
      environment.API_URL + '/everest/validate/' + everestId,
      { comments: comments },
    );
  }

  discardEverest(everestId: string): Observable<any> {
    return this._http.post(
      environment.API_URL + '/everest/discard/' + everestId,
      {},
    );
  }

  generateGMB(token: string, account: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + '/gmb/start-gmb-session', {
          token: token,
          account: account,
        })
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }

  getEmailQueue(): Observable<any> {
    return this._http.get(environment.API_URL + '/admin/email-queue-status');
  }

  getDeviceList(): Observable<any> {
    return this._http.get(environment.API_URL + '/device/list');
  }

  getDeviceDetails(deviceId: string): Observable<any> {
    return this._http.get(
      environment.API_URL + '/device/info-extended/' + deviceId,
    );
  }

  getAllStores(): Observable<any> {
    return this._http.get(environment.API_URL + '/stores/list');
  }

  checkDeviceAssignation(
    deviceId: string,
    currentStore: string,
    newStore: string,
    date: string,
  ): Observable<any> {
    const url =
      environment.API_URL +
      '/device/check-reassignation/' +
      deviceId +
      '/' +
      currentStore +
      '/' +
      newStore +
      '/' +
      (date ? date : 'all');
    return this._http.get(url);
  }

  deviceReassign(
    deviceId: string,
    currentStore: string,
    newStore: string,
    date: string,
    options: any,
  ): Observable<any> {
    let url =
      environment.API_URL +
      '/device/reassign/' +
      deviceId +
      '/' +
      currentStore +
      '/' +
      newStore +
      '/';

    if (date) {
      url += date;
    } else if (options.dontMove) {
      url += 'none';
    } else {
      url += 'all';
    }

    return this._http.post(url, options);
  }

  getGMBAccounts(): Observable<any> {
    let url = environment.API_URL + '/gmb/load-accounts';

    return this._http.get(url);
  }

  getGMBLocations(account: string): Observable<any> {
    let url = environment.API_URL + '/gmb/' + account;

    return this._http.get(url);
  }

  linkStoreToGMB(storeId: string, gmbId: string): Observable<any> {
    let url = environment.API_URL + '/gmb/link-store/' + storeId;

    return this._http.post(url, { gmb: gmbId });
  }

  getBackendStatus(): Observable<any> {
    let url = environment.API_URL + '/alive';

    return this._http.get(url);
  }

  getTrackingByStore(storeId: string): Observable<any> {
    let url = environment.API_URL + '/admin/ticket-tracking-detail/' + storeId;

    return this._http.get(url);
  }

  getTracking(): Observable<any> {
    let url = environment.API_URL + '/admin/ticket-tracking';

    return this._http.get(url);
  }

  createFunnel(funnel: any): Observable<any> {
    let url = environment.API_URL + '/funnel/new';

    return this._http.post(url, funnel);
  }

  getFunnel(funnel: any): Observable<any> {
    let url = environment.API_URL + '/funnel/info/' + funnel;

    return this._http.get(url);
  }

  getFunnels(): Observable<any> {
    let url = environment.API_URL + '/funnel/list';

    return this._http.get(url);
  }

  getHPConfig(): Observable<any> {
    let url = environment.API_URL + '/admin/hpconfig';

    return this._http.get(url);
  }

  setHPConfig(config: any): Observable<any> {
    let url = environment.API_URL + '/admin/hpconfig';

    return this._http.post(url, config);
  }

  getSurveyDefinition(): Observable<SurveyDefinition> {
    let url = environment.API_URL + '/admin/hpsurvey-config';

    return this._http.get<SurveyDefinition>(url);
  }

  getSurveyDefinitionSteps(
    survey: SurveyDefinition,
  ): Observable<SurveyDefinitionStep[]> {
    let url =
      environment.API_URL + '/admin/hpsurvey-config/' + survey._id + '/steps';

    return this._http.get<SurveyDefinitionStep[]>(url);
  }

  saveSurveyDefinitionSteps(
    step: SurveyDefinitionStep,
    survey: SurveyDefinition,
  ): Observable<any> {
    let url =
      environment.API_URL + '/admin/hpsurvey-config/' + survey._id + '/step';

    return this._http.post(url, step);
  }

  searchEmailQueue(text: String): Observable<any> {
    let url = environment.API_URL + '/admin/search-email-queue';

    return this._http.post<SurveyDefinition>(url, { search: text });
  }
}

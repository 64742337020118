import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GmbuserService } from "./gmbuser.service";
import { Injectable, NgZone } from "@angular/core";
import * as _ from "lodash";
import { GoogleAuthService, GoogleApiService } from "ng-gapi";

import GoogleUser = gapi.auth2.GoogleUser;
import GoogleAuth = gapi.auth2.GoogleAuth;

@Injectable()
export class GmbapiService {
  private _accounts;

  constructor(
    private http: HttpClient,
    private googleAuthService: GoogleAuthService,
    private googleApi: GoogleApiService,
    private ngZone: NgZone
  ) {
    this._accounts = [];
  }

  public getAccounts() {
    return this._accounts;
  }

  public loadAccounts() {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem(GmbuserService.SESSION_STORAGE_KEY);
      let url = "https://mybusiness.googleapis.com/v4/accounts/";

      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      xhr.onload = () => {
        if (xhr.status !== 200) {
          return;
        }

        const response = JSON.parse(xhr.response);
        this._accounts = response;
        resolve(this._accounts);
      };

      xhr.send();
    });
  }

  public loadStores(account: string) {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem(GmbuserService.SESSION_STORAGE_KEY);
      let url =
        "https://mybusiness.googleapis.com/v4/" + account + "/locations";

      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      xhr.onload = () => {
        if (xhr.status !== 200) {
          return;
        }

        const response = JSON.parse(xhr.response);
        resolve(response.locations);
      };

      xhr.send();
    });
  }

  public loadReviews(store: string) {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem(GmbuserService.SESSION_STORAGE_KEY);
      let url = "https://mybusiness.googleapis.com/v4/" + store + "/reviews";

      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      xhr.onload = () => {
        if (xhr.status !== 200) {
          return;
        }

        const response = JSON.parse(xhr.response);
        // debugger;
        resolve(response.locations);
      };

      xhr.send();
    });
  }
}

import { AdminApiService } from "./../admin-api.service";
import {
  Component,
  Input,
  ViewContainerRef,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { ToastrService } from "ngx-toastr";

import * as moment from "moment";

@Component({
  selector: "app-promotions-edit",
  templateUrl: "./promotions-edit.component.html",
  styleUrls: ["./promotions-edit.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PromotionsEditComponent implements OnInit, OnChanges {
  public _currentPromotion: any;
  public _promoID: string;
  public _uploadMode: boolean;
  public currentFile: any;
  public __sending: boolean;
  public _totalCodes: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  public _codeList: any;

  @Output()
  onEditEnded = new EventEmitter<boolean>();

  @Input()
  get promoID() {
    return this._promoID;
  }
  set promoID(promoID: string) {
    this._promoID = promoID;
  }

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef
  ) {
    this._currentPromotion = {};
    this._uploadMode = false;
    this._totalCodes = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this._codeList = [];
  }

  ngOnInit() {}

  ngOnChanges() {
    this.adminApi.loadPromotion(this._promoID).then((promo: any) => {
      this._currentPromotion = {
        name: promo.name,
        description: promo.description
      };
      this.loadCodes();
    });
  }

  close() {
    this.onEditEnded.emit(false);
  }

  savePromotion() {
    if (!this._currentPromotion.name) {
      this.toastr.error("Debe especificar un nombre para la promoción");
      return;
    }

    if (!this._currentPromotion.start) {
      this.toastr.error(
        "Debe especificar una fecha de inicio para la promoción"
      );
      return;
    }

    if (!this._currentPromotion.end) {
      this.toastr.error(
        "Debe especificar una fecha de finalización para la promoción"
      );
      return;
    }

    let start = moment(
      this._currentPromotion.start.year +
        "/" +
        this._currentPromotion.start.month +
        "/" +
        this._currentPromotion.start.day
    );

    let end = moment(
      this._currentPromotion.end.year +
        "/" +
        this._currentPromotion.end.month +
        "/" +
        this._currentPromotion.end.day
    );

    if (!start.isValid()) {
      this.toastr.error(
        "Debe especificar una fecha de inicio valida DD/MM/AAAA"
      );
      return;
    }

    if (!end.isValid()) {
      this.toastr.error(
        "Debe especificar una fecha de finalización valida DD/MM/AAAA"
      );
      return;
    }

    if (start >= end) {
      this.toastr.error(
        "Especifique un rango de fechas valido para la promoción."
      );
      return;
    }

    this.adminApi
      .createPromotion({
        name: this._currentPromotion.name,
        description: this._currentPromotion.description,
        start: start.toDate(),
        end: end.toDate()
      })
      .then(res => {
        this.toastr.success("Se ha guardado la promoción!");
        this.onEditEnded.emit(true);
      })
      .catch(err => {
        this.toastr.error(err.error.message);
      });
  }

  updateCodePage(page: number) {
    this._currentPage = page;
    this.loadCodes();
  }

  loadCodes() {
    this.adminApi
      .getPromotionCodes(
        this._promoID,
        this._currentPage,
        this._itemsByPage,
        {}
      )
      .then((codes: any) => {
        this._codeList = codes;
        this._totalCodes = codes.total;
        this._totalPages = Math.floor(codes.total / this._itemsByPage) + 1;
      });
  }
}

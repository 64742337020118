import { environment } from "./../../environments/environment";
import { AdminApiService } from "./../admin-api.service";
import { BaseApiService } from "./../base-api.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-developer-page",
  templateUrl: "./developer-page.component.html",
  styleUrls: ["./developer-page.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DeveloperPageComponent implements OnInit {
  public hideCronLauncher: boolean;
  public _developerStats: any;
  public _enableDevel: boolean;

  constructor(
    private adminAPI: AdminApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef
  ) {
    this.hideCronLauncher = false;
    this._developerStats = null;
    if (environment.enableDevelMode) {
      this._enableDevel = true;
    }
  }

  ngOnInit() {
    this.adminAPI.developerStats().then(dStats => {
      this._developerStats = dStats;
    });
  }

  launchCron(cronId: string) {
    // this.hideCronLauncher = true;
    this.adminAPI
      .cronLaunch(cronId)
      .then(() => {
        this.toastr.success(
          "Se ha programado la tarea CRON para ser lanzada. Espere aproximadamente 1 minuto y compruebe si se ha ejecutado correctamente."
        );
        return;
      })
      .catch(err => {
        this.toastr.error(err);
      });
  }

  launchDevel(action: string) {
    // <!> ng-auto-remove : ngRoot()->isDeveloper()
    this.toastr.error("Not available outside developer environment!");
  }
}

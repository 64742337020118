import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersPageComponent implements OnInit {
  public __currentCustomer: string;

  constructor() { }

  ngOnInit() {
    this.__currentCustomer = null;
  }

  showCustomer( cusId: string ){
    this.__currentCustomer = cusId;
  }

}

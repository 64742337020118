import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { AdminApiService } from "./../admin-api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-everest-detail-page",
  templateUrl: "./everest-detail-page.component.html",
  styleUrls: ["./everest-detail-page.component.scss"]
})
export class EverestDetailPageComponent implements OnInit {
  private __everestId: string;
  public __everest: any;

  public __currentComments: any;
  public currentCommentText: string;
  public currentCommentTextIndex: number;

  public __editCommentMode: boolean;
  public __viewMode: boolean;

  constructor(
    private adminApi: AdminApiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.everest_id) {
        this.__everestId = params.everest_id;
        this.__currentComments = [];
        this.currentCommentText = "";
        this.currentCommentTextIndex = -1;
        this.__editCommentMode = false;
        this.__viewMode = false;
        this.loadEverest();
      }
    });
  }

  loadEverest() {
    this.adminApi.getEverestDetails(this.__everestId).then(res => {
      this.__everest = res;

      if (this.__everest.validated || this.__everest.discarded) {
        this.__viewMode = true;
        if (this.__everest.validated) {
          this.__currentComments = this.__everest.bqComments;
        }
      }
    });
  }

  addComment(text: string) {
    this.__currentComments.push(text);
    this.currentCommentText = "";
    this.currentCommentTextIndex = -1;
    this.__editCommentMode = false;
  }

  editComment(index: number) {
    this.currentCommentText = this.__currentComments[index];
    this.currentCommentTextIndex = index;
    this.__editCommentMode = true;
  }

  saveComment(index: number, text: string) {
    if (!text || text === "") {
      this.toastr.error("Escribe un comentario o eliminalo.");
      return;
    }
    this.__currentComments[index] = text;
    this.currentCommentText = "";
    this.currentCommentTextIndex = -1;
    this.__editCommentMode = false;
  }

  removeComment(index: number) {
    this.__currentComments.splice(index, 1);
    this.currentCommentText = "";
    this.currentCommentTextIndex = -1;
    this.__editCommentMode = false;
  }

  newComment() {
    this.currentCommentText = "";
    this.currentCommentTextIndex = -1;
    this.__editCommentMode = false;
  }

  discard(discardModal) {
    this.modalService
      .open(discardModal)
      .result.then(result => {
        this.adminApi
          .discardEverest(this.__everestId)
          .toPromise()
          .then(res => {
            this.toastr.success("Se ha descartado el Everest");
            window.history.back();
          })
          .catch(error => {
            this.toastr.error(
              "Ha habido un error al descartar el everest, recargue la pagina y pruebe de nuevo."
            );
          });
      })
      .catch(err => {});
  }

  validate(alertModal) {
    if (this.__everest.validated) {
      this.modalService
        .open(alertModal)
        .result.then(result => {
          this.adminApi
            .updateEverestComments(this.__everestId, this.__currentComments)
            .toPromise()
            .then(res => {
              this.toastr.success(
                "Se han guardado los comentarios del Everest"
              );
              window.history.back();
            })
            .catch(error => {
              this.toastr.error(
                "Ha habido un error al actualizar los comentarios del everest, recargue la pagina y pruebe de nuevo."
              );
            });
        })
        .catch(err => {});
    } else {
      this.modalService
        .open(alertModal)
        .result.then(result => {
          this.adminApi
            .validateEverest(this.__everestId, this.__currentComments)
            .toPromise()
            .then(res => {
              this.toastr.success("Se ha validado el Everest");
              window.history.back();
            })
            .catch(error => {
              this.toastr.error(
                "Ha habido un error al validar el everest, recargue la pagina y pruebe de nuevo."
              );
            });
        })
        .catch(err => {});
    }
  }
}

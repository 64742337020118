import { AdminApiService } from "./../admin-api.service";
import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { GmbapiService } from "./../gmbapi.service";
import { GmbuserService } from "./../gmbuser.service";
import { GoogleAuthService } from "ng-gapi/lib/GoogleAuthService";
import { Component, OnInit } from "@angular/core";
import { GoogleApiService } from "ng-gapi";

import * as _ from "lodash";

@Component({
  selector: "app-gmbadmin-page",
  templateUrl: "./gmbadmin-page.component.html",
  styleUrls: ["./gmbadmin-page.component.scss"]
})
export class GmbadminPageComponent implements OnInit {
  public isLogged: boolean;
  public token: string;

  public _accounts: any;
  public _stores: any;
  public _storesInvalid: any;

  public _bqStores: any;

  public _storesReady: boolean;

  constructor(
    private userService: GmbuserService,
    private gmbApiService: GmbapiService,
    private authService: GoogleAuthService,
    private gapiService: GoogleApiService,
    vcr: ViewContainerRef,
    private toastr: ToastrService,
    private adminApi: AdminApiService
  ) {
    gapiService.onLoad().subscribe(() => {});
    this.isLogged = false;
    this.token = "";
    this._accounts = [];

    this._stores = [];
    this._storesInvalid = [];

    this._storesReady = false;

    this.adminApi.listAllStores().then((r: any) => {
      this._bqStores = r.items;
    });
  }

  public isLoggedIn(): boolean {
    this.isLogged = this.userService.isUserSignedIn();

    if (this.isLogged === true) {
      this.token = this.userService.getToken();
    }

    return this.isLogged;
  }

  public signIn() {
    this.userService.signIn();
  }

  public loadAccounts() {
    this.adminApi.getGMBAccounts().subscribe((result: any) => {
      this._accounts = result;
    });
  }

  public selectAccount(i) {
    _.each(this._accounts, acc => {
      acc.__isSelected = false;
    });

    this._accounts[i].__isSelected = true;
    this.loadStores();
  }

  public loadStores() {
    let selected = _.find(this._accounts, { __isSelected: true });

    if (!selected) {
      this.toastr.error(
        "Debes seleccionar una cuenta para ver las tiendas disponibles."
      );
      return;
    }

    this.adminApi.getGMBLocations(selected.name).subscribe(stores => {
      this._stores = [];
      _.each(stores, store => {
        let bqstore = _.find(this._bqStores, { gmbLocation: store.name });
        if (bqstore) {
          store.linkedStore = bqstore._id;
        }

        this._stores.push(store);
      });

      this._storesReady = true;
    });
  }

  generate() {
    let token = sessionStorage.getItem(GmbuserService.SESSION_STORAGE_KEY);
    let selected = _.find(this._accounts, { __isSelected: true });

    if (!selected) {
      this.toastr.error(
        "Debes seleccionar una cuenta para poder generar las estadisticas."
      );
      return;
    }

    this.adminApi
      .generateGMB(token, selected.name)
      .then(res => {})
      .catch(err => {});
  }

  loadStoreReviews(store: any) {
    this.gmbApiService.loadReviews(store.name).then(res => {
      // debugger;
    });
  }

  linkStore(store: any) {
    this.adminApi
      .linkStoreToGMB(store.linkedStore, store.name)
      .subscribe(res => {
        if (res.success) {
          this.toastr.success(
            "Se ha asignado la tienda al registro de Google My Business"
          );
        } else {
          this.toastr.error(
            "Ha habido un error al asignar la tienda al registro de Google My Business."
          );
        }
      });
  }

  ngOnInit() {
    this.loadAccounts();
  }
}

import { AdminApiService } from './../admin-api.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

export class FunnelRawData {
  public tom: Number = 0.1;
  public esp: Number = 0.1;
  public notoriety: Number = 0.1;
  public consideration: Number = 0.1;
  public purchase: Number = 0.1;
  public visit: Number = 0.1;
  public firstOption: Number = 0.1;

  public regionTitle: String;
  public subRegion: FunnelRawData[];

  constructor(text?: String) {
    this.subRegion = [];
    if (text) {
      this.regionTitle = text;
    }
  }
}

@Component({
  selector: 'app-funnel-editor-page',
  templateUrl: './funnel-editor-page.component.html',
  styleUrls: ['./funnel-editor-page.component.scss'],
})
export class FunnelEditorPageComponent implements OnInit {
  public _currentPage: String = 'regions';
  public _funnelList: any;
  public _selectedFunnel: any;
  public _selectedRegion: FunnelRawData;
  public _selectedProvince: FunnelRawData;

  public influenceTotal: FunnelRawData = new FunnelRawData();
  public influenceInside: FunnelRawData = new FunnelRawData();
  public influenceOutside: FunnelRawData = new FunnelRawData();

  public regions: FunnelRawData[];

  public funnelName: String = '';
  public newProvinceName: String = '';

  public _showEditor: Boolean = false;

  constructor(
    private toastr: ToastrService,
    private adminApi: AdminApiService,
  ) {}

  ngOnInit() {
    this.regions = [];

    this.loadFunnelList();
  }

  loadFunnelList() {
    this.adminApi.getFunnels().subscribe((funnels: any) => {
      this._funnelList = funnels;
    });
  }

  saveFunnel() {
    console.log('Funnel title: %s', this.funnelName);
    console.log('Influence total  : %o', this.influenceTotal);
    console.log('Influence Inside : %o', this.influenceInside);
    console.log('Influence Outside: %o', this.influenceOutside);
    console.log('Current regions: %o', this.regions);

    this.adminApi
      .createFunnel({
        title: this.funnelName,
        influenceTotal: this.influenceTotal,
        influenceInside: this.influenceInside,
        influenceOutside: this.influenceOutside,
        regions: this.regions,
      })
      .subscribe((result: any) => {}, (error: any) => {});
  }

  editFunnel() {
    console.log('Editing funnel: %s', this._selectedFunnel);
    this.adminApi.getFunnel(this._selectedFunnel).subscribe(
      (funnel: any) => {
        this.funnelName = funnel.title || '';
        this.influenceTotal = funnel.influenceTotal || new FunnelRawData();
        this.influenceInside = funnel.influenceInside || new FunnelRawData();
        this.influenceOutside = funnel.influenceOutside || new FunnelRawData();
        this.regions = [];
        for (let region of funnel.regions) {
          let r = region;
          r.regionTitle = region.title;
          r.subRegion = [];

          for (let province of region.provinces) {
            let p = province;
            p.regionTitle = province.title;
            r.subRegion.push(p);
          }

          this.regions.push(r);
        }
        this._showEditor = true;
      },
      (error: any) => {
        this.toastr.error('Ha habido un error al cargar los datos del funnel!');
      },
    );
  }

  newFunnel() {
    this._showEditor = true;
    this.funnelName = null;
    this.influenceTotal = new FunnelRawData();
    this.influenceInside = new FunnelRawData();
    this.influenceOutside = new FunnelRawData();

    let r = new FunnelRawData();
    r.regionTitle = 'Centro';
    r.subRegion.push(new FunnelRawData('Madrid'));
    r.subRegion.push(new FunnelRawData('Avila'));
    r.subRegion.push(new FunnelRawData('Ciudad Real'));
    r.subRegion.push(new FunnelRawData('Toledo'));
    this.regions.push(r);

    r = new FunnelRawData();
    r.regionTitle = 'Norte';
    r.subRegion.push(new FunnelRawData('A Coruña'));
    r.subRegion.push(new FunnelRawData('Cantabria'));
    r.subRegion.push(new FunnelRawData('Leon'));
    r.subRegion.push(new FunnelRawData('Palencia'));
    r.subRegion.push(new FunnelRawData('Pontevedra'));
    r.subRegion.push(new FunnelRawData('Valladolid'));
    r.subRegion.push(new FunnelRawData('Zamora'));
    this.regions.push(r);

    r = new FunnelRawData();
    r.regionTitle = 'Sur';
    r.subRegion.push(new FunnelRawData('Almeria'));
    r.subRegion.push(new FunnelRawData('Badajoz'));
    r.subRegion.push(new FunnelRawData('Cadiz'));
    r.subRegion.push(new FunnelRawData('Granada'));
    r.subRegion.push(new FunnelRawData('Malaga'));
    r.subRegion.push(new FunnelRawData('Sevilla'));
    this.regions.push(r);

    r = new FunnelRawData();
    r.regionTitle = 'Este';
    r.subRegion.push(new FunnelRawData('Barcelona'));
    r.subRegion.push(new FunnelRawData('Girona'));
    r.subRegion.push(new FunnelRawData('Murcia'));
    r.subRegion.push(new FunnelRawData('Tarragona'));
    r.subRegion.push(new FunnelRawData('Valencia'));
    this.regions.push(r);
  }
}

export class User {
  _id = "";
  avatar = "";
  token = "";
  expire = "";
  name = "";
  surname = "";
  username = "";
  email = "";
  jobTitle = "";
  groups = [];
  isAdmin = false;
  isDeveloper = false;
  active = false;
  banned = false;
  newPassword = "";
  newPasswordCheck = "";
  phone = "";
  allowedStores = [];
}

export class ApiUpdateMsg {
  date = "";
  user = "";
  notes = "";
}

export class Device {
  _id = "";
  name = "";
  code = "";
  store = "";
}

export class MailVariable {
  name = "";
  match = "";
  replace = "";
}

export class MailTemplate {
  code = "";
  name = "";
  internalId = "";
  description = "";
  created = ApiUpdateMsg;
  variables = new Array(MailVariable);
  email = { title: "", html: "", plain: "" };
}

import { AdminApiService } from "./../admin-api.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  animation
} from "@angular/animations";

import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-alert-list",
  templateUrl: "./alert-list.component.html",
  styleUrls: ["./alert-list.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AlertListComponent implements OnInit {
  public _alerts: Object;

  public _showAlertDetail: boolean;
  public _currentAlert: Object;
  public _totalAlerts: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this._showAlertDetail = false;
    this._totalAlerts = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this.loadAlerts();
  }

  onRowClick(alert: any) {
    this._showAlertDetail = true;
    this._currentAlert = alert;
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadAlerts();
  }

  loadAlerts() {
    this.adminApi
      .getAlerts(this._currentPage, this._itemsByPage, {})
      .then((alerts: any) => {
        this._alerts = alerts.items;
        this._totalAlerts = alerts.total;
        this._totalPages =
          Math.floor(this._totalAlerts / this._itemsByPage) + 1;
      });
  }

  reviewAll() {
    this.adminApi
      .markAlertsReviewed()
      .then(res => {
        this.toastr.success("Se han marcado todas las alertas como revisadas!");
        this.updatePage(0);
      })
      .catch(err => {
        this.toastr.error("Ha habido un error al revisar las alertas.");
      });
  }
}

import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { AdminApiService } from "../admin-api.service";

@Component({
  selector: "app-admin-device-list",
  templateUrl: "./admin-device-list.component.html",
  styleUrls: ["./admin-device-list.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AdminDeviceListComponent implements OnInit {
  public deviceList: any;
  public _totalDevices: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalItems: number;
  public _totalPages: number;
  public _currentFilter: any;

  @Output()
  onSelectedDevice = new EventEmitter<string>();

  @Input()
  set currentFilter(filter: any) {
    this._currentFilter = filter;
  }
  get currentFilter() {
    return this._currentFilter;
  }

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this.deviceList = [];
    this._currentPage = 0;
    this._itemsByPage = 20;
    this._totalDevices = 0;
    this._totalPages = 1;
    this._totalItems = 0;
    this._currentFilter = {};

    this.loadDevices();
  }

  edit(device_id: string) {
    this.onSelectedDevice.emit(device_id);
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadDevices();
  }

  loadDevices() {
    this.adminApi
      .listDevices(this._currentPage, this._itemsByPage, this._currentFilter)
      .then((res: any) => {
        this._totalItems = res.total;
        this.deviceList = res.items;
        this._totalPages = Math.floor(res.total / this._itemsByPage) + 1;
      });
  }
}

import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from './../admin-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-tracking',
  templateUrl: './alert-tracking.component.html',
  styleUrls: ['./alert-tracking.component.scss'],
})
export class AlertTrackingComponent implements OnInit {
  public _storeList: any;
  public _selectedStore: string;

  public _trackingDetails: any;
  public _trackingStats: any;

  public __storeLoading: boolean;

  constructor(
    private adminApi: AdminApiService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this._storeList = [];
    this.__storeLoading = false;

    this.loadData();
  }

  loadData() {
    this.adminApi.getAllStores().subscribe(stores => {
      this._storeList = stores.items;
    });

    this.adminApi.getTracking().subscribe(stats => {
      this._trackingStats = stats;
    });
  }

  updateStoreInfo() {
    console.log('Selected store: ' + this._selectedStore);
    this.__storeLoading = true;
    this.adminApi.getTrackingByStore(this._selectedStore).subscribe(
      trackingDetails => {
        this.__storeLoading = false;
        this._trackingDetails = trackingDetails;
      },
      error => {
        this.toastr.error('Error loading store details!');
        console.dir(error);
        this.__storeLoading = false;
      },
    );
  }
}

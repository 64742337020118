import { BqAuthService } from './../bq-auth.service';
import { User } from './../bqdata-model';
import { AdminApiService } from './../admin-api.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-admin-users-list',
  templateUrl: './admin-users-list.component.html',
  styleUrls: ['./admin-users-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminUsersListComponent implements OnInit {
  public _userList: any;
  public _totalUsers: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  private _updateList: boolean;
  public _isDeveloper: Boolean = false;

  @Input()
  set updateList(updateList: boolean) {
    if (updateList === true) {
      this.loadUsers();
    }
  }
  get updateList(): boolean {
    return this._updateList;
  }

  @Output()
  onSelectedUser = new EventEmitter<string>();

  userList: any;
  constructor(
    private adminApi: AdminApiService,
    private bqAuth: BqAuthService,
  ) {}

  ngOnInit() {
    this._isDeveloper = this.bqAuth.isDeveloper();
    this._userList = [];
    this._totalUsers = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this.loadUsers();
  }

  editUser(userId) {
    this.onSelectedUser.emit(userId);
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadUsers();
  }

  loadUsers() {
    this.adminApi
      .listUsers(this._currentPage, this._itemsByPage, {})
      .then((res: any) => {
        console.log(res);
        this._userList = res.items;
        this._totalUsers = res.total;
        this._totalPages = Math.round(res.total / this._itemsByPage) + 1;
        this._updateList = false;
      })
      .catch(err => {});
  }
  blockUsers(idUser){
    console.log(idUser);
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-playground-page',
  templateUrl: './playground-page.component.html',
  styleUrls: ['./playground-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlaygroundPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
